@import url(../../../styles/theme.css);

.sideBar{
    width: var(--sidebarWidth) !important;
    max-width: var(--sidebarWidth) !important;
    position: relative;
}
.sideBar.ant-layout-sider-collapsed{
    width: var(--sidebarcollapsedWidth) !important;
    max-width: var(--sidebarcollapsedWidth) !important;
}

.sideBar, .sideBar .ant-menu, .ant-layout-sider-trigger{
    background-color: var(--sidebarBg);
}
.sideBar .ant-menu-item{
    border-left: 3px solid var(--sidebarBg);
    color: var(--sidebarText);
    border-bottom: 1px solid #5a5a5a;
    margin-bottom: -5px !important;
    padding: 29px 0;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item{
    margin-bottom: -5px !important;
    padding: 25px;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item img, .ant-menu.ant-menu-inline-collapsed .ant-menu-item span{
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item span{
    left: 150px;
}

.sideBar .ant-menu-item-selected{
    background-color: var(--menuSelected) !important;
    border-left: 5px solid #227F99;
}

.sideBar .ant-menu{
    font-size: var(--sidebarFontSize);
    font-weight: 600;
    /* margin-top: 10px; */
}
.signoutBtnContainer{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 85%;
    border-top: 1px solid grey;
    width: 80%;
    padding-top: 20px;
}

.signoutBtn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--sidebarText);
    font-size: var(--sidebarFontSize);
}
/* .logout-icon{
    margin-right: 24px;
} */

.signoutBtn img{
    margin-right: 24px;
    width: 32px;
    height: 32px;
}

.signoutBtn:hover{
    color: #ffffff;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item span {
    left: 50%;
    opacity: 0;
    padding: 50px;
}

@media (min-width:768px) and (max-width:1024px) {
    .signoutBtnContainer {
        padding-top: 5px;
        padding-left: 10px;
    }
}