/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */
@import url(../src/styles/theme.css);
@import url('~@fontsource/nunito-sans/index.css');  
@import url('~@fontsource/nunito-sans/600.css');  
@import url('~@fontsource/nunito-sans/700.css');    
@import url('~@fontsource/nunito-sans/900.css');   
@import url('~@fontsource/open-sans/index.css');  
@import url("~@fontsource/open-sans/500.css");   
@import url('~@fontsource/open-sans/600.css');   
@import url('~@fontsource/open-sans/700.css');   
@import url('~@fontsource/ibm-plex-sans/index.css');      
@import url('~@fontsource/ibm-plex-sans/600.css');   
@import url('~@fontsource/ibm-plex-sans/700.css');   
@import url('~@fontsource/ibm-plex-sans/500.css');   


body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.uicard{
  background: #ffffff !important;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 10%);
  /* margin-bottom: 0.938rem !important; */
  border-radius: 10px !important;
}

/* .f-20{
  font-size: 1.38vw;
} */
/* @media only screen and (max-width: 998px) {
  .f-20{
    font-size: 2.30vw !important;
  }
}

@media only screen and (max-width: 680px) {
.f-14{
  font-size: 1.5vw !important;
}
}

@media (min-width: 680px) and (max-width: 820px) {
.f-14{
  font-size: 1.8vw !important;
}
}

@media (min-width: 820px) and (max-width: 1280px) {
 .f-14{
   font-size: 1.1vw !important;
 }
}

@media (min-width: 998px) and (max-width: 1280px) {
.f-20{
  font-size: 2.30vw !important;
}
}

@media (min-width: 1280px) and (min-width: 1660px) {
.f-20{
  font-size: 1.38vw !important;
}
.f-14{
  font-size: 0.972vw !important;
}
}*/

.f-14{
  font-size: 14px;
} 

.p-20{
  padding: 20px !important;
}

.space-between{
  justify-content: space-between;
}

.flex-end{
  justify-content: flex-end;
}

.align-items-center{
  align-items: center;
}

.d-flex{
  display: flex;
}

.d-inline-block{
  display: inline-block;
}

.d-block{
  display: block;
}

.text-center{
  text-align: center;
}

.w-100{
  width: 100%;
}

.title-section .title{
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
  padding-top: 5px;
}

.p-10{
  padding: 10px;
}

.pr-10{
  padding-right: 10px;
}

.pt-10{
  padding-top: 10px;
}

.pb-10{
  padding-bottom: 10px;
}

/* .uicard .icon {
  width:45px;
} */

.search{
  margin-right: 0 !important;
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: center;
}

.title-section .actions{
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px){
  .title-section .actions{
    justify-content: space-between;
  }
}
@media (min-width: 500px) and (max-width: 640px){
  .title-section .title{
    font-size: 14px;
  }
}

.slice{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-dropdown-menu-item-icon {
  min-width: 16px !important;
  margin-right: 10px !important;
  font-size: 16px !important;
}

.ant-dropdown-menu{
  border-radius: 5px !important;
  padding: 0px 0px !important;
  /* width: 193px; */
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 42px !important;
    padding: 0 15px !important;
    margin-right: 4px;
    color: var(--dropdownTextColor) !important;
}

.ant-dropdown-menu-title-content{
  padding-top: 3px !important;
  flex: auto;
  white-space: nowrap;
}

.actions .search{
  padding-right: 30px;
}

/* .uicard.table .card-body{
  min-height: 62vh;
} */

.tablePlaceholder{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tablePlaceholder .icon{
  width: 80px;
  margin: 15px auto;
}

.tablePlaceholder .text{
  font-size: 16px;
}

.ant-layout-footer{
  padding: 0 50px;
}

.actionBtns .ant-space{
  flex-wrap: wrap;
}

.title-section .actions{
  flex-wrap: wrap;
}

::-webkit-scrollbar{
  height: 4px;
  width: 4px;
}

.ant-card-body{
  height: 100%;
}

.successMessage .ant-message-notice-content{
  padding: 13px 20px !important;
  background-color: #37b400 !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
  border-radius: 5px !important;
}

.successMessage .ant-message-success .anticon-check-circle{
  color: #fff !important;
}

.successMessage .ant-message-success:last-child{
  color: white;
  font-weight: 700;
}

.errorMessage .ant-message-notice-content{
  padding: 14px 20px !important;
  background-color: #fcbeb8;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
  border-radius: 5px !important;
}

.infoMessage .ant-message-notice-content{
  padding: 14px 20px !important;
  background-color: #E9F3F5;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
  border-radius: 5px !important;
}

.errorMessage .ant-message-error .anticon{
  color: #7e0c00 !important;
}

.infoMessage .ant-message-info .anticon{
  color: #0E5C72 !important;
}

.errorMessage .ant-message-error:last-child{
  color: #7e0c00 !important;
}

.infoMessage .ant-message-info:last-child{
  color: #0E5C72 !important;
}


.clickable {
  padding: 5px 0;
  cursor: pointer;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active{
  background-color: rgba(34, 127, 153, 0.1) !important;
}

.ant-table-ping-right .ant-table-cell-fix-right-first:after{
  box-shadow: none !important;
}



/* .elipses.ant-table-cell-fix-right-first{
  position: relative;
  right: 27px !important;
} */

.ellipses{
  font-size: 35px;
  color: #2E2E2E !important;
}
.ellipses:hover{
  color: #138098 !important;
}

.ant-input-affix-wrapper {
  padding: 0px !important;
}

.listing-section .uicard.table .card-body,
.listing-section>.ant-card {
  height: 100%;
}

.ant-tooltip-inner{
  color:#fff !important;
  background-color: #0E5C72 !important;
  font-family: 'Nunito Sans';
  font-style: normal;
  box-shadow: none !important;
  border: 1px solid #138098;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #0E5C72 !important;
}

.paragraph{
  margin-bottom: 0px !important;
}

@media (min-width:768px) and (max-width:1024px) {
  /* .ant-card-body{
    padding: 7px 20px 7px 20px !important;
  } */

}