  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
  }
  .dropdown {
    border: 1px solid rgba(46, 46, 46, 0.2) !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25)) !important;
    border-radius: 5px !important;
    padding: 0 !important;
  }
  
  .custom {
    width: 100%;
    box-sizing: border-box !important;
    font-style: normal;
    font-weight: normal;
  }
  .custom .ant-select-disabled .ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: #f5f5f5 !important;
    }
  
   .custom .ant-select-selector {
      background-color: #fefefe !important;
      height: 40px !important;
      color: #2e2e2e !important;
      border-radius: 5px !important;
      align-items: center !important;
      font-size: 14px !important;
    }
    .custom .ant-select-selection-search-input {
      height: 40px !important;
    }
  
  .options {
    background: transparent !important;
    border-left: 2px solid transparent;
  }
  
  .options:hover {
      background: rgba(137, 137, 137, 0.05) !important;
      border-left: 2px solid #227f99;
    }
  
  .options.ant-select-item-option-active{
    font-weight: 600 !important;
    border-left: 2px solid #227f99;
  }
  
  .multiselect .ant-checkbox {
    float: right !important;
  }
  
  .checkbox {
    float: right !important;
  }
  
  .ib-0 {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    color: #161616 !important;
    margin-bottom: 0px !important;
  }
  
  .ib-1 {
      border-radius: 5px !important;
      height: 40px;
    }

  .ant-select-dropdown{
    padding: 0px !important;
  }