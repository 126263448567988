.faq-container {
    padding: 30px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .faq-container h2 {
    text-align: center;
    margin-bottom: 25px;
    color: #227F99;
    font-size: 1.8rem; /* Larger font size */
  }
  
  .ant-collapse {
    border: none;
  }
  
  .ant-collapse-header {
    background-color: rgba(233, 236, 239, 0.3);
    color: #227F99 !important; 
    font-weight: 500;
    padding: 16px;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-size: 1rem; /* Larger font size */
  }
  
  .ant-collapse-content {
    background-color: #ffffff;
    border: 1px solid #e6f7ff;
    border-radius: 0 0 4px 4px;
    padding: 16px;
  }
  
  .ant-collapse-content p {
    margin: 0;
    color: #555252;
    font-family: 'Open Sans';
    font-size: 1rem; /* Larger font size */
  }
  
  .ant-collapse-content-active > .ant-collapse-content {
    border-color: #91d5ff;
  }
  
  .extra{
    padding-right: 1vw;
    padding-left: 1vw;
  }

  .margin{
    padding-top: 1vw;
  }