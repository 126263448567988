/* .listing-section{
    height: 93%;
} */
.card-body-device{
    height: 100%;
}
.card-content{
    min-height: 200px;
    border:  1px solid #87CDE6;
    border-radius: 0.5rem;
    background-color:  rgba(233, 236, 239, 0.3);
}
.card-container{
    display: flex;
    width: 100%;
}
.card-box{
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}
.card-heading{
    font-size: 20px;
}
.card-img{
    margin-top: 20px;
}
.online{
    margin-left: 19px;
    font-size: 20px;
    color:#8EC549;
    margin-top: 5px;
}
.offline{
    color: #DC143C;
    margin-left: 19px;
    font-size: 16px;
    margin-top: 5px;
}

.off-sync{
    color: gray;
    margin-left: 19px;
    font-size: 20px;
    margin-top: 5px;
}
/* .uiinner-card{
    height: 85vh;
} */
.deviceDetail{
    text-transform: capitalize;
}

.onlineDevice{
    font-size: 16px;
    color:#8EC549;
    margin-top: 8px;
    margin-left: 16px;
}

.offlineDevice{
    color: #DC143C !important;
    font-size: 16px;
    margin-top: 8px;
    margin-left: 16px;
}

.noDataFound{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}