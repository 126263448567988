.activityLogsTable {
    height: 100%;
}
td.noPadCell{
    padding: 8px !important;
}

.title {
    font-size: 20px;
    color: #2E2E2E;
}

.deviceImgCard {
    border: 1px solid #87CDE6;
    border-radius: 0.5rem;
    /* margin: 15px 40px 2px 40px; */
    height: 60%;
}

.onlineoffline{
    text-align: end;
    margin-top: 10px;
}

.deviceCardDetail {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.scan {
    font-size: large;
}

.uhfImg {
    position: absolute;
    bottom: 33%;
    right: 47%;
}

.syncclock {
    width: 21px;
    margin-right: 10px;
    margin-bottom: 5px;
}


.timeStatus {
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 20px;
}

.lastSync {
    color: #898989;
    margin-left: 5px;
    font-weight: 400;
}

.flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.itemCapture {
    flex-direction: column;
    font-size: 16px;
    color: #898989;
    margin-right: 5px;
}

.marginLeft {
    color: #2E2E2E;
}

.hr {
    margin-top: 5px;
    color: #E0E0E0;
    width: 100%;
}

.cardBottom {
    margin-top: 20px;
}

/* .capture{
    display: flex;
    justify-items: left;
    text-align: left;
} */

.card-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px !important;
}

.activeContent {
    font-size: 18px;
    text-transform: capitalize;
    color: #2E2E2E;
}

.margin {
    margin-top: 20px;
}

.synced {
    margin-right: 20px;
}

.colorOnline {
    color: #8EC549;
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px;
}

.colorOffnline{
    color: #DC143C !important;
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px;
}

.justifyText{
    justify-content: space-between;
    display: flex;
}

.columnContent {
    text-align: right;
}

.currentTime {
    font-weight: 600;
}

.reloadButton{
    margin-left: 10px;
}

.reloadButton:hover{
    cursor: pointer;
    color: #227f99;
    font-weight: 800px;
}

.reload{
    justify-content: end;
    display: flex;
}

.refresh{
    color:#227f99 !important ;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}

.refresh:hover{
    cursor: pointer;
}

@media screen and (max-width: 1140px){
    .deviceImgCard{
        margin: 15px 6px 15px 6px;
    }
}

@media (min-width: 1140px) and (max-width: 1180px){
    .deviceImgCard{
        margin: 15px 20px 15px 20px;
    }
}



/* @media screen and (max-width: 1400px) {
    .deviceImgCard{
        margin: 40px 52px 20px 52px;
    }
}
@media screen and (max-width: 1100px){
    .cardBottom{
        margin-top: 30px;  
    }
    .deviceImgCard{
        margin: 35px 25px 20px 25px;
    }
}
@media screen and (max-height: 1030px){
    .scan{
        margin-top: 50px;
    }
} */

@media (max-width: 1024px) and (max-height:768px) {

    .processAction{
        justify-content: center;
    }

    .deviceImgCard {
        margin: 0px;
        height: 67%;
    }

    .scanTag {
        height: 50px !important;
        width: 50px !important;
        padding: 7px !important;
        margin-left: 0px !important;
        border-radius: 50% !important;
    }

    .scanText {
        font-size: 10px;
        margin-left: 25px !important;
        text-align: center;
    }

}

@media (min-width:768px) and (max-width:1150px) {
    .spanTag{
        margin-left: 5px;
        padding-left: 0px;
        }
    .colorOnline{
        margin-top: 5px;
    }
    .device1img{
        height: 20%;
        width: 20%;
    }
    .activeContent{
        font-size: 12px;
        margin-top: 10px;
    }
    
    .timeStatus{
        margin-top: 10px;
    }
    /* .cardBottom{
        margin-top: 5px;
    } */
    .uicard.table .activityTable1{
        margin-bottom: 0px !important;
    }
    .activitytable .uitable .ant-table{
        height: 360px;
    }  
}
.activityTable1 .ant-table-body{
    max-height: 400px !important;
}

@media (max-height:900px) and (max-width:1400px) {
    .activityLogsTable .uitable .ant-table{
        height: 380px;
    }
    .activityTable1 .ant-table-body{
        max-height: 330px !important;
    }
}

.scanTag {
    background-color: rgb(120, 119, 119);
    height: 70px;
    width: 70px;
    padding: 13px;
    border-radius: 50%;
}

@media (min-width: 1024px) and (max-width:1180px) {
    .scanTag {
        height: 65px !important;
        width: 65px !important;
    }
}

@media screen and (max-width: 1024px) {
    .scanTag {
        height: 59px !important;
        width: 59px !important;
    }


    .loopborder {
        left: 40px !important;
        right: 38px !important;
        top: 29px !important;
    }
}

@media screen and (max-width: 770px) {
    .scanTag {
        height: 50px !important;
        width: 50px !important;
    }

    .looparrow {
        /* left: 30px !important; */
    }

    .loopborder {
        left: 36px !important;
        right: 34px !important;
        top: 23px !important;
    }
}

.processAction {
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}

.deviceScanTag{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.processStepsRow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    flex-wrap: nowrap;
    margin-left: -1px !important;
  }
  
  .stepswrapper {
    position: relative;
    height: 160px;
  }
  
  .processStepsRow .loopborder{
    content: "";
    display: block;
    min-height: 60px;
    position: absolute;
    top: 35px;
    border-bottom: 1px solid grey;
    border-right: 1px solid grey;
    border-left: 1px solid grey;

  }
  
  .processStepsRow .loopborder1{
    content: "";
    left: 45px;
    right: 45px;
    display: block;
    min-height: 60px;
    position: absolute;
    top: 35px;
    border-top: 1px solid grey;
  }

  .processStepsRow .looparrow {
    content: "";
    height: 0;
    position: absolute;
    transition: all 0.5s linear;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 11px solid grey;
    /* left: 40px; */
    bottom: -11px;
  }

  .processModal .stepswrapper .stepBox{
    width: 100px;
    position: relative;
  }
  
  .processModal .stepBox .icon {
    background-color: #9c9c9c;
    border-radius: 50%;
    position: relative;
    margin: 10px auto;
    height: 70px;
    width: 70px;
  }
  
  .processModal .stepBox .title {
    height: 40px;
  }
  
  .processModal .stepBox.active .icon {
    background-color: #000;
  }
  
  .stepBox.large .icon {
    height: 120px;
    width: 120px;
    background-color: #227f99;
  }
  
  .processModal .stepBox.large img {
    padding: 30px;
  }
  
  .processModal .stepBox img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 20px;
  }
  
  .processModal .stepBox.large .title {
    font-size: 16px;
  }
  
  .pulse {
    /* box-shadow: 0 0 0 rgba(204,169,44, 1); */
    animation: pulse 1s infinite;
  }
  .pulse:hover {
    animation: none;
  }
  .icon.pulse{
    border-radius: 100%;
}
.icon.active .scanTag{
    
    background-color: #000000;
}

.stepBox{
    text-align: center;
}

.titleName{
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
}

.processCR{
    position: relative;
}


  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
        box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }

  .upperRow{
    justify-content: center;
    display: flex;
  }

  .spanTag{
    padding-left: 10px;
    padding-right: 10px;
    /* max-width: 100px; */
  }

  .backButton{
    font-size: 18px;  
    color: #000;
  }
  .backButton:hover{
    color: #227f99;
    cursor: pointer;
  }
  .left-back-button{
    font-size: 13px;
    font-weight: bold;
  }
  .top-back-text{
    font-size: 13px;
    /* margin-left: 10px; */
    text-decoration: underline; 
  }

  .colorIcon{
    background-color: #227f99 !important;
  }

  .centerTable{
    text-align: center !important;
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    background-color: transparent; /* semi-transparent black overlay */
    z-index: 1000; /* ensure overlay is above other page content *
    max-width: 600px; /* adjust maximum width as needed */
    padding: 16px;
  }
  
  .alertColor{
    background-color: #fbdfe4;
    border-color: #fbdfe4;
  }

.ant-alert-info .ant-alert-icon svg{
    color: red !important;
  }

.notificationdiv {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    justify-content: start;
    text-align: left;
}

.heading-rs{
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoneNameCSS{
    text-transform: capitalize;
    margin-left: 5px;
}