.ant-modal-title {
    font-size: 18px !important;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600 !important;
    color: #2e2e2e !important;
  }
  
  .modalClass .ant-modal-content {
    border-radius: 8px;
  }
  
  .modalClass .ant-modal-header {
    border-radius: 8px;
    padding: 20px 20px;
  }
  
  .modalClass .ant-modal-body {
    padding: 10px 20px;
  }
  
  .modalClass .ant-modal-footer {
    padding: 16px 30px;
  }
  
  .ant-modal-wrap {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
  
  
  @media (min-width:768px) and (max-width:1024px) {
    .ant-modal-wrap {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0% !important;
        overflow: auto;
        outline: 0;
        -webkit-overflow-scrolling: touch;
    }
  }
  
  .ant-modal {
    position: initial !important;
  }