.tableData {
    text-transform: capitalize;
}

.pagination {
    display: flex;
    margin: 10px;
    justify-content: flex-end;
    align-items: center;
}

.first-icon {
    padding: 4px;
    width: 75px;
    height: 40px;
    font-size: 1.2rem;
    border: 1px solid gray;
    padding-top: 3px;
}

.right-icon {
    margin-left: 25px;
}

.page-name {
    font-size: 1.1rem;
    margin-top: 10px;
    margin-left: 20px;
    text-align: center;
}

.second {
    display: flex;
}

.second-icon {
    border: 1px solid gray;
    width: 35px;
    height: 35px;
    font-size: 1.3rem;
    text-align: center;
    margin-left: 20px;
    padding-top: 3px;
    margin-top: 6px;
}

.third {
    display: flex;
    margin-left: 10px;
}

.third-icon {
    display: flex;
    width: 60px;
    height: 50px;
    font-size: 1.3rem;
    border-bottom: 1px solid gray;
    text-align: center;
    margin-left: 10px;
    padding-top: 10px;
}

.download {
    margin-left: 35px;
    font-size: 0.7rem;
    margin-top: 8px;
}

.siteDeviceform .ant-modal-body {
    padding: 30px 90px;
}

.siteFormHeader {
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    /* padding-left: 50px;
    padding-right: 50px; */
    margin-bottom: 4px;
}

/* .uiinner-card{
    height: 85vh;
} */

.ant-alert-error {
    border: 0px !important;
    margin-bottom: 15px;
    background-color: #FDD1CC;
    color: #7E0C00 !important;
}

.ant-alert {
    color: red;
}

.messageCss .div {
    background-color: #7E0C00;
}

.clickable {
    padding: 5px 0px;
    cursor: pointer;
}

.playbtn {
    font-size: 20px;
}

 .clickable:hover .playbtn path{
    fill: #138098 !important;
}

.executeProcessTable{
    cursor: pointer;
}

.errorMsg{
    height: 40px;
}

.custom-spin-container{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

