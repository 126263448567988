.scrollContent {
    max-height: 100%;
    overflow-y: auto;
    padding-right: 1vw;
    padding-left: 1vw;
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 20px;
  }
  .scrollStyle::-webkit-scrollbar {
    width: 5px;
  }
    
    /* Track */
  .scrollStyle::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #9e9e9e71;
  }
     
    /* Handle */
  .scrollStyle::-webkit-scrollbar-thumb {
    background: #4C5254;

    border-radius: 5px;
  }
    
    /* Handle on hover */
  .scrollStyle::-webkit-scrollbar-thumb:hover {
    background: #4C5254;
    ;  
  }
  .scrollStyle::-webkit-scrollbar-thumb {
    background: #4C5254 !important;
    border-radius:5px !important
;
}
  
  
  