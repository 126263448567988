.avatar{
    border-radius: 50%;
    position: relative;
}

.avatar .content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-weight: 600;
    font-family: 'Open Sans';
}