.inclass .ant-modal-content{
    border-radius: 8px;
    position: relative;
    top: 300px;
}

.cssCol{
    align-items: center;
    justify-content: center;
    text-align: center;
}

.cssCol{
    color: red;
}

.errorAlert{
    margin-bottom: 25px;
}