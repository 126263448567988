@import url(../../../styles/theme.css);
.uiBtn{
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size:  14px;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    height: 40px;
    color: var(--btnTextColor);
    width: 100%;
    text-align: center;
}

.secondaryBtn{
    box-shadow: 0px;
    color: var(--secondaryButton);
    background-color:white;
    border: none;
}

.uiBtn:hover{
    filter:hue-rotate(25deg);
}


 @media (max-width: 465px) {
      .uiBtn.xl {
        width: 256px;
      }
    }

.uiBtn.primary{
    border: 1px solid var(--primary);
    background-color: var(--primary);
}

.disableBtn:disabled{
    opacity: 0.5;
    box-shadow: none;
    background-color: gray !important;
    color: black;
    cursor: not-allowed;
}

.secondaryBtn:disabled{
    pointer-events: none;
    box-shadow: none;
    background-color: var(--disableBtnBgColor);
    color: var(--disableBtnTextColor);
}

.uiBtn.danger{
    border: 1px solid var(--danger);
    background-color: var(--danger);
}


.uiBtn.warning{
    border: 1px solid var(--warning);
    background-color: var(--warning);
}


.uiBtn.info{
    border: 1px solid var(--info);
    background-color: var(--info);
}

.uiBtn.success{
    border: 1px solid var(--success);
    background-color: var(--success);
}



.iconBtn{
    display: inline-flex;
    align-items: center;
    border: none;
    background: none;
    color: var(--btnTextColor);
    box-shadow: none;
}

.iconBtn img{
    height: 15px;
    margin-right: 5px;
}
.iconBtn span{
    padding-left: 8px;
    padding-top: 5px;
}


.blockBtn{
  width: 100%;
  background-color: #227F99;
  color: #fff;
}