.detailed-top-card .ant-card-body{
    padding: 12px !important;
}

.title-section{
    margin-bottom: 20px;
}

.colPadding{
  padding-left: 10px;
}

/* .colPaddingdetail{
    margin-top: 20px;
} */

.tableAudit .ant-table-cell-ellipsis{
    white-space: break-spaces;
}

/* .calendercsscol{
    margin-top: 20px;
} */

.identityCalendar{
    box-shadow: 0px 0px 5px 0px;
}

.identityCalendar .ant-row{
    flex-wrap: nowrap !important;
}

.auditLogs{
    margin-right: 15px;
}