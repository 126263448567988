.tableData{
    text-transform: capitalize;
}

.activeProcessTable{
    cursor: pointer;
}

.dot-green{
    width: 10px;
    height: 10px;
    background-color: green; /* Default color */
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    margin-right: 5px;
}

.dot-red{
    width: 10px;
    height: 10px;
    background-color: rgb(201, 6, 6); /* Default color */
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    margin-right: 5px;
}

.dot-yellow{
    width: 10px;
    height: 10px;
    background-color: rgb(255, 217, 0); /* Default color */
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    margin-right: 5px;
}