@import url(../../../styles/theme.css);
.fixedHeader{
      padding: 0;
      width: 100%;
      z-index: 999;
      position: fixed;
      background: white;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 0px 0px;
}

.header{ 
    display: flex;
    align-items: center;
    color: var(--grey05);
}

.header .sclogo{
    display: flex;
    align-items: center;
    padding: 20px;
}

.stopexitbuttonClass{
    margin-top: 50px;
}

.header .logo {
    background-color: var(--headerLogoBg);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebarWidth{ width: var(--sidebarWidth) !important; transition: 0.2s;}
.collapsedWidth{ width: var(--sidebarcollapsedWidth) !important; transition: 0.2s;}


.header .hammerberg {
    padding: 0 20px;
    cursor: pointer;
    border-right: 1px solid #2E2E2E33;
    position: absolute;
    top: 50%;
    height: 60%;
    transform: translateY(-50%);
}

.hammerberg img{
    transform: translateY(50%);
}

.ant-breadcrumb ol{
    font-size: 16px;
    margin-top: 22px;
    font-weight: 500;
}

.ant-layout-header{
    line-height: initial;
}

.profileImage{
    padding-right: 20px;
    overflow: hidden;
}

.profileImageSection{
    display: flex;
    align-items: center;
    padding: 0 29px;
    color: var(--headerFontColor);
    position: absolute;
    right: 0;
    top: 50%;
    height: 60%;
    border-left: 1px solid #2E2E2E33;
    transform: translateY(-50%);
    cursor: pointer;
}

.profileImage img{
    width: 50px;
    height:50px;
    border-radius: 50%;
    object-fit: cover;
}

.profileImageSection .name{
    font-weight: 600;
    font-size: 16px;
}
.profileImageSection .info{
    padding-right: 25px;
}

.columnPassword{
    margin-top: 30px;
}

.userIcon.ant-dropdown-menu-item-icon{
    background: #227F99;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative;
}
.ant-dropdown-menu-item:hover{
    background: rgba(34, 127, 153, 0.1);
}
.userIcon img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.info .name{
    text-transform: capitalize;
}

.ant-menu-inline>.ant-menu-item, .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-left>.ant-menu-item, .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical-right>.ant-menu-item, .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical>.ant-menu-item, .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title{
    height: 60px;
    line-height:60px
}

.header .sclogo .logotext{
    padding: 8px;
}

.ant-layout-header{
    height: 72px;
}
    
.session_expired_alert{
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #00000078;
    z-index: 99999;
}

.session_expired_alert .content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    padding: 20px;
    border-radius: 5px;
}

.session_expired_alert .content .message{
    color: #f44336;
    font-size: 16px;
}

.session_expired_alert .btn{
    margin-top: 15px;
    text-align: center;
}

.session_expired_alert .uiBtn.md {
    padding: 5px 15px;
    font-size: 14px;
}

.redCrossImg {
    position: relative;
    text-align: center;
    
    /* bottom: 10px; */
  }
  
  .alertText {
    position: relative;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    display: flex !important;
    align-items: center;
    letter-spacing: 0.002em;
    color: #2e2e2e;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    top: 25px;
    /* margin-top: 7px;
    margin-bottom: 20px; */
    /* text-transform: capitalize; */
  }
  
  .alertStop .ant-modal-title {
    font-weight: bold;
    font-size: 20px;
  }
  
  /* .ant-modal {
    width: 553px;
  } */
  
  .alertStop .ant-modal-body {
    padding: 60px;
  }
  
  .alertStop .ant-modal-content{
      border-radius: 8px;
      position:relative;
      top: 300px;
      height: 250px
  }

  .alertStop.ant-modal{
    z-index: 9999;
  }
  
  .warning .anticon svg{
      height: 80%;
      width: 80%;
  }

  .closCross{
    justify-content: end;
  }